// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-5-godar-grundir-js": () => import("./../../../src/pages/5-godar-grundir.js" /* webpackChunkName: "component---src-pages-5-godar-grundir-js" */),
  "component---src-pages-burokt-js": () => import("./../../../src/pages/burokt.js" /* webpackChunkName: "component---src-pages-burokt-js" */),
  "component---src-pages-competition-js": () => import("./../../../src/pages/competition.js" /* webpackChunkName: "component---src-pages-competition-js" */),
  "component---src-pages-competition-teaser-js": () => import("./../../../src/pages/competition-teaser.js" /* webpackChunkName: "component---src-pages-competition-teaser-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-english-js": () => import("./../../../src/pages/english.js" /* webpackChunkName: "component---src-pages-english-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kalaallisut-js": () => import("./../../../src/pages/kalaallisut.js" /* webpackChunkName: "component---src-pages-kalaallisut-js" */),
  "component---src-pages-mbm-js": () => import("./../../../src/pages/mbm.js" /* webpackChunkName: "component---src-pages-mbm-js" */),
  "component---src-pages-samband-js": () => import("./../../../src/pages/samband.js" /* webpackChunkName: "component---src-pages-samband-js" */),
  "component---src-pages-staff-js": () => import("./../../../src/pages/staff.js" /* webpackChunkName: "component---src-pages-staff-js" */),
  "component---src-pages-storv-js": () => import("./../../../src/pages/storv.js" /* webpackChunkName: "component---src-pages-storv-js" */),
  "component---src-pages-studul-js": () => import("./../../../src/pages/studul.js" /* webpackChunkName: "component---src-pages-studul-js" */),
  "component---src-pages-takk-fyri-js": () => import("./../../../src/pages/takk-fyri.js" /* webpackChunkName: "component---src-pages-takk-fyri-js" */),
  "component---src-pages-tidindi-js": () => import("./../../../src/pages/tidindi.js" /* webpackChunkName: "component---src-pages-tidindi-js" */),
  "component---src-pages-um-okkum-js": () => import("./../../../src/pages/um-okkum.js" /* webpackChunkName: "component---src-pages-um-okkum-js" */),
  "component---src-pages-uppskriftir-js": () => import("./../../../src/pages/uppskriftir.js" /* webpackChunkName: "component---src-pages-uppskriftir-js" */),
  "component---src-pages-vorur-js": () => import("./../../../src/pages/vorur.js" /* webpackChunkName: "component---src-pages-vorur-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-recipie-js": () => import("./../../../src/templates/recipie.js" /* webpackChunkName: "component---src-templates-recipie-js" */)
}

